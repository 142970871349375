textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

.kanban-button {
    min-height: 60px;
    transition: all 2s;
}

.kanban-button .kanban-title {
    width: 90%;
}

.kanban-button .kanban-info {
    width: 10%;
}

.kanban-button.unchecked .kanban-title {
    background-color: red;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    -webkit-animation: flip-check 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) forwards;
    animation: flip-check 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) forwards;
}

.kanban-button.checked .kanban-title {
    background-color:#198754;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
	-webkit-animation: flip-uncheck 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) forwards;
	        animation: flip-uncheck 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) forwards;
}


 @-webkit-keyframes flip-check {
    0% {
      background-color: #198754;
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    50% {
        background-color: #198754;
      -webkit-transform: rotateX(-90deg);
              transform: rotateX(-90deg);
    }
    51% {
        background-color: #dc3545;
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
    }
    100% {
        background-color: #dc3545;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
  }
  @keyframes flip-check {
    0% {
        background-color: #198754;
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
      }
      50% {
        background-color: #198754;
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
      }
      51% {
          background-color: #dc3545;
          -webkit-transform: rotateX(-90deg);
                  transform: rotateX(-90deg);
      }
      100% {
          background-color: #dc3545;
          -webkit-transform: rotateX(0deg);
                  transform: rotateX(0deg);
      }
  }

  @-webkit-keyframes flip-uncheck {
    0% {
      background-color: #dc3545;
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    50% {
        background-color: #dc3545;
      -webkit-transform: rotateX(-90deg);
              transform: rotateX(-90deg);
    }
    51% {
        background-color: #198754;
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
    }
    100% {
        background-color: #198754;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
  }
  @keyframes flip-uncheck {
    0% {
        background-color: #dc3545;
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
      }
      50% {
        background-color: #dc3545;
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
      }
      51% {
          background-color: #198754;
          -webkit-transform: rotateX(-90deg);
                  transform: rotateX(-90deg);
      }
      100% {
          background-color: #198754;
          -webkit-transform: rotateX(0deg);
                  transform: rotateX(0deg);
      }
  }